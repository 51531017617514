<!--文章详情-->
<template>
  <div>
    <div style="display: flex;justify-content: space-between;align-items: center;">
      <div>
        <span style="font-size: 24px;font-weight: 500;color: #707070;">知识库文章详情</span>
      </div>
      <div>
        <el-button style="background: #E4E5E9!important;border-color: #E4E5E9!important;color: #3F4157!important;" @click="returnPage()">返回</el-button>
      </div>
    </div>

    <el-card shadow="never" style="margin-top: 20px;">
      <div style="display: flex;align-items: center;">
        <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
        <span style="font-size: 20px;font-weight: 700;color: #202033;">筛选条件</span>
      </div>

      <el-form
        ref="form"
        :model="form"
        label-width="80px">

        <el-row :gutter="15" style="margin-top: 20px;">
          <el-col :span="12">
            <el-form-item label="栏目">
              <div>
                <span v-for="(item) in column" style="margin-right: 10px;">
                  {{item.label}}
                </span>
              </div>
            </el-form-item>
            <el-form-item label="文章标题">
              <el-input
                clearable
                :maxlength="20"
                v-model="form.title"
                placeholder="请输入标题"/>
            </el-form-item>
            <div style="border: 1px solid #ccc;">
              <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
              <Editor
                style="height: 500px; overflow-y: hidden;"
                v-model="form.content"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
              />
            </div>
          </el-col>
          <el-col :span="12">
            <div style="display: flex;align-items: center;">
              <span style="font-size: 16px;font-weight: 500;color: #4e4e4e;">选择栏目</span>
              <el-button class="custom-button" style="background: #3F4157!important;border-color: #3F4157!important;color: #FFFFFF!important;margin-left: 10px;" @click="add()">
                <i class="el-icon-tianjia" />
                <span style="vertical-align: middle">添加一级栏目</span>
              </el-button>
            </div>

            <div style="border: 1px solid #e3e4e9;border-radius: 6px;padding: 20px;margin-top: 20px;">
              <el-tree
                ref="treeData"
                @check-change="getTree"
                @check="handleCheckChange"
                :data="data"
                show-checkbox
                node-key="id"
                default-expand-all
                :default-checked-keys="checkedKeys"
                :expand-on-click-node="false">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <span>{{ node.label }}</span>
                  <span>
                    <!--<el-button-->
                    <!--  type="text"-->
                    <!--  size="mini"-->
                    <!--  @click="() => append(data)">-->
                    <!--  Append-->
                    <!--</el-button>-->
                    <img src="@/assets/images/home/yunying/append.png" alt="" style="width: 20px;height: 20px;" @click="addsecondLevel(node,data)" v-if="node.level == 1 || node.level == 2">
                    <!--<el-button-->
                    <!--  type="text"-->
                    <!--  size="mini"-->
                    <!--  @click="() => remove(node, data)">-->
                    <!--  Delete-->
                    <!--</el-button>-->
                    <img src="@/assets/images/home/yunying/del.png" alt="" style="width: 20px;height: 20px;margin-left: 10px;" @click="del(node,data)">
                  </span>
                </span>
              </el-tree>
            </div>

          </el-col>
        </el-row>

      </el-form>
    </el-card>

    <div style="margin-top: 20px;text-align: center;">
      <el-button style="width: 240px;">取消</el-button>
      <el-button
        style="margin-left: 20px;width: 240px;"
        type="primary"
        @click="save()">提交
      </el-button>
    </div>

    <el-dialog
      center
      title="添加一级"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div>
        <el-form
          ref="form"
          :model="treeForm"
          label-width="80px">
          <el-input
            clearable
            :maxlength="20"
            v-model="treeForm.name"
            placeholder="请输入名称"/>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      center
      title="添加"
      :visible.sync="ShowsecondLevel"
      width="30%"
      :before-close="ShowsecondLevelhandleClose">
      <div>
        <el-form
          ref="form"
          :model="secondLeveltreeForm"
          label-width="80px">
          <el-input
            clearable
            :maxlength="20"
            v-model="secondLeveltreeForm.name"
            placeholder="请输入名称"/>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="ShowsecondLevel = false">取 消</el-button>
        <el-button type="primary" @click="secondLeveldetermine">确 定</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
  import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
  import {
    Addknowledge,
    Addknowledge_category,
    Delknowledge_category, Detailsknowledge,
    Editknowledge,
    getknowledge_category
  } from "@/api/operate";

  export default {
    props: {
      // 修改回显的数据
      dataObject: Object
    },
    components: { Editor, Toolbar },
    data(){
      return{
        editor: null,
        html: '',
        toolbarConfig: { },
        editorConfig: { placeholder: '请输入内容...' },
        mode: 'default', // or 'simple'

        dialogVisible: false,

        // data: JSON.parse(JSON.stringify(data)),
        data:[],
        treeForm:{
          name:'',
        },

        //二级
        ShowsecondLevel:false,
        secondLeveltreeForm:{
          name:''
        },
        node:{},
        where:{},
        // 栏目
        column:[],

        // 表单数据
        form: {},
        // 是否是修改
        isUpdate: false,

        // 默认选中的树形数据
        checkedKeys:[],

      }
    },

    watch: {
      data() {
        if (this.dataObject) {
          console.log(this.dataObject)
          this.form = {};
          Detailsknowledge(this.dataObject.id).then(res => {
            console.log(res)
            this.form = res.data;
            // 默认选中
            this.checkedKeys = [];
            this.checkedKeys.push(res.data.cid);

            // 栏目显示
            this.column = [];
            // 获取选中的节点和半选中节点
            this.data.forEach(item => {
              if(item.children != undefined){
                item.children.forEach(it => {
                  if(it.id == res.data.cid){
                    let where ={
                      label:item.label
                    }
                    let where1 ={
                      label:it.label
                    }
                    this.column.push(where,where1)
                  }
                })
              }
            })
          })
          this.isUpdate = true;
        } else {
          this.form = {};
          this.isUpdate = false;
        }
      }
    },

    mounted() {
      console.log(this.isUpdate)

      // 调用获取分类列表接口
      this.getClass();
    },

    methods:{
      //获取分类
      getClass(){
        getknowledge_category().then(res => {
          console.log(res)
          this.data = res.data;
        })
      },

      //点击返回按钮
      returnPage(){
        this.$emit('showDetails',false);
      },

      onCreated(editor) {
        this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      },

      save(){
        console.log(this.html);
        //判断是新增还是修改
        if(this.isUpdate === false){
          Addknowledge(this.form).then(res => {
            console.log(res)
            this.loading = false;
            if (res.code === 200) {
              this.$message.success(res.msg);
              if (!this.isUpdate) {
                this.form = {};
              }
              this.$emit('showDetails',false);
              this.$emit('done');
            } else {
              this.$message.error(res.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.msg);
          })
        }else {
          let data = this.form;
          Editknowledge(data.id,data).then(res => {
            console.log(res)
            this.loading = false;
            if (res.code === 200) {
              this.$message.success(res.msg);
              if (!this.isUpdate) {
                this.form = {};
              }
              this.$emit('showDetails',false);
              this.$emit('done');
            } else {
              this.$message.error(res.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.msg);
          })
        }
      },

      //一级关闭
      handleClose(done) {
        this.dialogVisible = false;
      },
      //一级添加
      add(){
        this.dialogVisible = true;
      },
      // 添加一级栏目
      determine(){
        let data = {
          pid: 0,
          title: this.treeForm.name
        }
        Addknowledge_category(data).then(res => {
          if(res.code === 200){
            //隐藏添加一级栏目弹窗
            this.dialogVisible = false;
            this.treeForm.name = '';
            this.getClass();
            this.$message.success(res.msg)
          }else {
            this.$message.error(res.msg)
          }
        }).catch(e => {
          this.$message.error(e.msg);
        })

      },


      // 添加栏目
      addsecondLevel(node,data){
        console.log(node);
        console.log(data);
        let datata = {
          pid: data.id,
          title: this.secondLeveltreeForm.name,
        }
        this.where = datata;
        this.ShowsecondLevel = true;
      },
      //关闭
      ShowsecondLevelhandleClose(){
        this.ShowsecondLevel = false;
      },
      //添加
      secondLeveldetermine(){
        this.where.title = this.secondLeveltreeForm.name
        Addknowledge_category(this.where).then(res => {
          if(res.code === 200){
            this.ShowsecondLevel = false;
            this.secondLeveltreeForm.name = '';
            this.getClass();
            this.$message.success(res.msg)
          }else {
            this.$message.error(res.msg)
          }
        }).catch(e => {
          this.$message.error(e.msg);
        })
      },

      // 删除分类
      del(node,data){
        console.log(node);
        console.log(data)
        Delknowledge_category(data.id).then(res => {
          if(res.code === 200){
            this.getClass();
            this.$message.success(res.msg)
          }else {
            this.$message.error(res.msg)
          }
        }).catch(e => {
          this.$message.error(e.msg);
        })
      },

      getTree(data, checked) {
        this.form.cid = data.id;
        if (checked) {
          this.$refs.treeData.setCheckedKeys([data.id]);
          //data 即为选择的集合值
        }
      },
      handleCheckChange(datas) {
        const data = this.data[0].children;
        const array = this.$refs.treeData.getCheckedNodes();
        // 获取选中的节点和半选中节点
        const AllcheckedNodes = this.$refs.treeData.getCheckedNodes(false, true);
        console.log(AllcheckedNodes)
        this.column = AllcheckedNodes;
        // 选中的节点的索引
        const chilIndex = AllcheckedNodes.findIndex(item => item.label === datas.label);
        // 截取索引1 到 选中的之间的数据
        const newAll = AllcheckedNodes.slice(1, chilIndex);
        // 反转数据并拿到的第一个就是当前选中的父节点
        const parent = newAll.reverse().find(item => item.children);
      }


    },

    beforeDestroy() {
      const editor = this.editor
      if (editor == null) return
      editor.destroy() // 组件销毁时，及时销毁编辑器
    }

  }
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
